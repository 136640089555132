import { buildProjectAtomics } from "@frend-digital/centra/src/productFetchers";
import type {
  BaseCentraProduct,
  CentraBaseRelation,
  CentraMedia,
  CentraMediaObjects,
  CentraProductAllPrices,
  CentraProductSinglePrices,
} from "@frend-digital/centra/types/product";
import { z } from "zod";

import KeysToZodSchema from "../utils/keysToZodSchema";

// It is crucial that these are cast as read only, otherwise the types will not resolve correctly
export const mediaKeys = ["full", "standard", "large-2560"] as const;
const customRelations = ["Look"] as const;
export type MediaKeys = (typeof mediaKeys)[number];

// custom project-specific attributes for the zod selection schema.
export const customSelectionSchema = {
  media: KeysToZodSchema(mediaKeys, z.array(z.string())).partial(),
  product: z
    .object({
      pr_color: z.object({ name: z.string(), code: z.string() }),
      pr_composition_value: z.string(),
      pr_fit_advice: z.string(),
      pr_garment_care: z.string(),
    })
    .partial(),
};
export type customMediaSchema = z.infer<typeof customSelectionSchema.media>;
export type customProductSchema = z.infer<typeof customSelectionSchema.product>;

type CustomMediaAttributes = {
  img_config_alt_text?: string;
};

type Relation = (typeof customRelations)[number] | CentraBaseRelation;

// TODO: build/place actual type here
type CustomProjectAttributes = {
  media: CentraMedia<MediaKeys>;
  mediaObjects: CentraMediaObjects<MediaKeys, CustomMediaAttributes>;
  relation: Relation;

  // display
  dis_long_desc_web_text: string;
  dis_short_desc_web_text: string;

  dis_usp_1_text: string;
  dis_usp_2_text: string;
  dis_usp_3_text: string;
  dis_usp_4_text: string;
  dis_usp_5_text: string;

  dis_order_value: string;
  dis_shipping: string;

  dis_meta_keywords: string;

  // variant
  var_description_value: string;
  var_compliance_certifications_text: string;
  var_env_impact_text: string;
  var_fedas_color1: string;
  var_fedas_color2: string;
  var_fedas_color3: string;
  var_strap_material_value: string;
  var_strap_material_cog: string;
  var_exterior_main_materials_value: string;
  var_exterior_main_materials_cog: string;
  var_exterior_main_materials_supplier: string;
  var_exterior_main_materials_raw_cog: string;
  var_interior_main_materials_value: string;

  var_color: {
    name: string;
    code: string;
    image: any;
    border?: string;
  };

  var_labels: [
    {
      card: string;
      text: string;
      dark: string;
    },
  ];

  // product
  pr_long_description_value: string;
  pr_description_value: string;

  pr_usp_1_text: string;
  pr_usp_2_text: string;
  pr_usp_3_text: string;
  pr_usp_4_text: string;
  pr_usp_5_text: string;

  pr_fit_advice: string;
  pr_garment_care: string;
  pr_fabric_props: any;
  pr_faq: any;
  pr_video_value: string;
  pr_coming_soon_value: string;
  pr_engraving_key: string;
  pr_care: string;
  pr_gender_value: string;
  pr_height_value: string;
  pr_length_value: string;
  pr_width_value: string;
  pr_climate_props: [{ label: string }];
  dis_color: any;
  pr_series: {
    value: string;
    display: boolean;
  };
  pr_shape: {
    value: string;
    display: boolean;
  };
  bundleInfo: boolean;
  bundleData: {
    bundle: any;
  };
};
export type CentraProduct = BaseCentraProduct<CustomProjectAttributes>;
export type CentraProductServer =
  CentraProductAllPrices<CustomProjectAttributes>;
export type CentraProductClient =
  CentraProductSinglePrices<CustomProjectAttributes>;

export const {
  baseAtomics,
  clientAtomics,
  serverAtomics,
  isServerProduct,
  selectionProductAtomics,
} = buildProjectAtomics<
  CentraProductServer,
  CentraProductClient,
  MediaKeys,
  Relation
>();

// very simple dummy that just returns the data directly, replace with actual formatters.
// note the typing here, in that it must take one argument, which is the centra product with project specific attributes passed as a generic

// This can also be async, in case subsequent calls need to be made after fetching product data.
// This is deliberately built to prevent easily smashing a PC call inside the PDP call, build a separate function for that, and Promise.all() the PCs

// Note that there are no multiple fetchers built, in order to have atomic cache for each individual PC & PDP
