import env from "@/env.mjs";
import {
  BaseSelectionItem,
  BaseSelectionResponse,
} from "@frend-digital/centra-types/selection";

import {
  buildGiftHooks,
  buildSelectionFetchers,
  buildSelectionHooks,
  type DefaultSelection,
} from "@frend-digital/future-centra/client";
import {
  getSelectionItemCategory,
  getSelectionItemId,
  getSelectionItemLine,
  getSelectionItemName,
  getSelectionItemPriceEach,
  getSelectionItemPriceEachBeforeDiscount,
  getSelectionItemQuantity,
  getSelectionItemSize,
  getSelectionItemSku,
  getSelectionItemUri,
  getSelectionItemVariantName,
  getSelectionProductId,
} from "../centra/selectionAtoms";

export const centraClient = buildSelectionFetchers({
  apiUrl: env.NEXT_PUBLIC_CENTRA_CHECKOUT_API,
});

const generateGiftItem = (item: BaseSelectionItem) => {
  const itemId = getSelectionItemId(item) ?? "";

  const name: string = getSelectionItemName(item) ?? "";
  const uri: string = getSelectionItemUri(item) ?? "";
  const variant = getSelectionItemVariantName(item) ?? "";
  const sku = getSelectionItemSku(item) ?? "";
  const size: string = getSelectionItemSize(item) ?? "";
  // const priceAsNumber = getSelectionItemPriceEachAsNumber(item) ?? 0; // TODO ADD THIS BACK
  const price: string = getSelectionItemPriceEach(item) ?? "";
  const discountedPrice = getSelectionItemPriceEachBeforeDiscount(item) ?? "";
  const quantity: number = getSelectionItemQuantity(item) ?? 0;
  const category: string = getSelectionItemCategory(item) ?? "";
  const productId = getSelectionProductId(item) ?? "";

  // TODO: why do we need both line and id if they have the same value?
  const id: string = getSelectionItemLine(item) ?? "";
  const line = item.line ?? "";

  return {
    name,
    uri,
    variant,
    size,
    id,
    price,
    quantity,
    discountedPrice,
    itemId,
    line,
    sku,
    category,
    productId,
  };
};
const giftFormatter = (data: BaseSelectionResponse) => {
  const formattedGift = data?.selection?.items?.map((item) =>
    generateGiftItem(item),
  );

  return {
    ...data,
    data: {
      selection: { items: formattedGift, address: data.selection?.address },
    },
    rawSelection: data,
    centraCheckoutScript: data.selection?.centraCheckoutScript,
  };
};
export type GiftFormat = ReturnType<typeof giftFormatter>;

export const centraGiftClient = buildSelectionFetchers<GiftFormat>({
  apiUrl: env.NEXT_PUBLIC_CENTRA_CHECKOUT_API,
  formatter: giftFormatter,
});

export const futureCentra = buildSelectionHooks({
  fetchers: centraClient,
});

export const futureCentraHooks = futureCentra;

export const giftHooks = buildGiftHooks<GiftFormat>({
  fetchers: centraGiftClient,
});

export type SelectionItem = NonNullable<
  DefaultSelection["selection"]["items"]
>[number];

export type Selection = DefaultSelection;
